import React, { Component } from "react";

class MainGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titlegird: [
                {
                    id: 1,
                    title: "¿Quiénes somos?",
                    description:
                        "Godínez Madrigal y Asociados S. C. es una firma de contadores públicos que cuenta  con más de 29 años de experiencia prestando servicios profesionales de Auditoría, Contabilidad, Consultoría fiscal, legal y constitución de sociedades mercantiles y asociaciones civiles.",
                },
            ],
            boxgrid: [
                {
                    id: "1",
                    srcimg: "images/about/t01.jpg",
                    subtitle: "¿Quiénes somos?",
                    title: "Godínez Madrigal y Asociados S. C. es una firma de contadores públicos quecuenta con más de 29 años de experiencia prestando servicios profesionales deAuditoría, Contabilidad, Consultoría fiscal, legal y constitución de sociedades mercantiles y asociaciones civiles.",
                   
                },
                {
                    id: "2",
                    srcimg: "images/about/t02.jpg",
                    subtitle: "Founder &amp; CEO",
                    title: "STEVEN MONROE",
                },
                {
                    id: "3",
                    srcimg: "images/about/t03.jpg",
                    subtitle: "Finance & Commerce",
                    title: "MICHAEL KING",
                },
                {
                    id: "4",
                    srcimg: "images/about/t04.jpg",
                    subtitle: "Company Director",
                    title: "ROMEO ALVAREZ",
                },
            ],
        };
    }
    render() {
        return (
            <section className="flat-row pd-about-team">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.titlegird.map((data) => (
                                <div
                                    className="title-section center s1"
                                    key={data.id}
                                >
                                    <h2>{data.title}</h2>
                                    <p className="sub-title-section">
                                        Godínez Madrigal y Asociados S. C. es
                                        una firma de contadores públicos que
                                        cuenta con más de 29 años de experiencia
                                        prestando servicios profesionales de
                                        Auditoría, Contabilidad, Consultoría
                                        fiscal, legal y constitución de
                                        sociedades mercantiles y asociaciones
                                        civiles.
                                    </p>
                                    <h2>Misión</h2>
                                    <p className="sub-title-section">
                                        Prestar servicios profesionales de
                                        contaduría y consultoría fiscal de
                                        excelente calidad y profesionalismo;
                                        para satisfacer las necesidades de cada
                                        uno de nuestros clientes con atención
                                        personalizada buscando siempre superar
                                        sus expectativas.
                                    </p>
                                    <h2>Visión</h2>
                                    <p className="sub-title-section">
                                        Ser una firma de contadores, reconocidos
                                        por nuestros clientes por rebasar
                                        expectativas y requerimientos de la
                                        información contable, fiscal,
                                        administrativa y consultoría fiscal,
                                        brindando seguridad en sus empresas y/o
                                        negocios.
                                    </p>
                                    <h2>Valores</h2>
                                    <p className="sub-title-section">
                                        Responsabilidad: Tenemos un compromiso
                                        con cada uno de nuestros clientes y
                                        colaboradores, nos comprometemos a
                                        entregar cada uno de nuestros trabajos
                                        en tiempo, forma y siempre con
                                        excelencia.
                                    </p>
                                    <p className="sub-title-section">
                                        Transparencia: Nos ganamos la confianza
                                        de nuestros clientes cumpliendo de
                                        manera oportuna, puntual y correcta en
                                        la entrega de su información contable,
                                        financiera y fiscal.
                                    </p>
                                    <p className="sub-title-section">
                                        Nuestros clientes reciben cada mes una
                                        carpeta digital de sus archivos de
                                        trabajo que se utilizaron para la
                                        elaboración de sus declaraciones
                                        mensuales y un respaldo de su
                                        contabilidad a través de la nube.
                                    </p>
                                    <h2>Nuestra Historia</h2>
                                    <p className="sub-title-section">
                                        Nuestra Historia Nuestros directores
                                        Agustín Godínez y Juanita Madrigal
                                        fundaron la Firma Godínez Madrigal y
                                        Asociados en el mes de Julio de 1994,
                                        después de haber laborado para varias
                                        firmas de contadores y en vísperas de su
                                        graduación de la carrera de Contador
                                        Público y Auditor de la Facultad de
                                        Contaduría Pública de la Benemérita
                                        Universidad Autónoma de Puebla, tuvieron
                                        el sueño de formar una firma de
                                        contadores públicos para brindar
                                        servicios de mejor calidad; deciden unir
                                        experiencia y conocimientos para cumplir
                                        este sueño.
                                    </p>
                                </div>
                            ))}
                            <div className="dividers dividers-about-team"></div>
                        </div>
                    </div>

                    <div className="row">
                        {this.state.boxgrid.map((data) => (
                            <div className="col-md-6" key={data.id}>
                                <div className="flat-team team-grid has-image">
                                    <div className="team-image">
                                        <img src={data.srcimg} alt="img" />
                                    </div>
                                    <div className="team-info">
                                        <div className="team-name">
                                            {data.subtitle}
                                        </div>
                                        <div className="team-subtitle">
                                            {data.title}
                                        </div>
                                        <div className="team-desc"></div>
                                        
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            //   flat-row-iconbox
        );
    }
}

export default MainGrid;
