import React, { Component } from "react";
import { Header, Footer } from "../layouts/general";
import { Breadcrumbs } from "../layouts/general/featured/Breadcrumbs";
import { ServiciosComponent } from "../layouts/general/featured/ServiciosComponent";
class RiskManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: "Pages",
                },
            ],
            titleheading: [
                {
                    id: "1",
                    title: "Services Grid",
                },
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: "Servicios",
                    classicon: "fa fa-angle-right",
                    aria: "true",
                },
            ],
        };
    }
    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    {/* <Loader /> */}
                    {/* <TopBar /> */}
                    {this.state.headers.map((data) => (
                        <Header data={data} key={data.id} />
                    ))}
                    {/* <div className="page-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        <h1 className="h1-title">Servicios</h1>
                                    </div>
                                    <ul className="breadcrumbs">
                                        {
                                            this.state.breadcrumbs.map(data =>(
                                                <li key={data.id} ><Link to="#" title="">{data.title}<i className={data.classicon} aria-hidden={data.aria}></i></Link></li>
                                            ))
                                        }
                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <Breadcrumbs
                    title={"Servicios"}
                    breadCrumbs={"Home > Servicios"}
                    imageLink={"images/contadoresServicios.jpg"}
                />
                <ServiciosComponent />
                <Footer />
            </div>
        );
    }
}

export default RiskManagement;
