import React, { useEffect, useState } from "react";
import "../styles/GMLoader.css";

export const GMLoader = () => {
    const [toggle, setToggle] = useState(true);
    const timer = React.useRef(null);

    const startTimer = () => {
        timer.current = setTimeout(() => {
            setToggle(false);
        }, 5000);
    };

    useEffect(() => {
        if (localStorage.getItem("firstLoading") === null) {
            startTimer();
            localStorage.setItem("firstLoading", false);
        } else {
            setToggle(false);
        }

        return () => clearTimeout(timer.current);
    },[]);

    return (
        <div className={toggle ? "gm-loader" : "gm-loader2"}>
            <div className="loaderElement"></div>
        </div>
    );
};
