import React from "react";
import "../styles/Breadcrumbs.css";

export const Breadcrumbs = ({ title, breadCrumbs, imageLink }) => {
    return (
        <div
            className="containerBreadCrumbs"
            style={{
                backgroundImage: `url(${imageLink})`,
            }}
        >
            <div>
                <h2>{title}</h2>
                <h4>{breadCrumbs}</h4>
            </div>
        </div>
    );
};
