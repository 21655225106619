import React from "react";
import "../styles/Testimonios.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

SwiperCore.use([Pagination]);

export function Testimonios() {
    return (
        <div className="contenedorTestimonios">
            <div class>
                <h1 className="tituloTestimonios">Testimonios</h1>
            </div>
            <div className="swiper-margin">
                <Swiper
                    modules={[Pagination]}
                    slidesPerView={1}
                    autoplay={{ delay: 2 }}
                    pagination={{ clickable: true }}
                >
                    <SwiperSlide>
                        <div className="testimonio-a">
                            <div className="image fondo1"></div>
                            <div className="textoTestimonio">
                                <p className="fraseTestimonio1">
                                    {" "}
                                    "Un contador confiable cómo la roca, siempre
                                    busca actualizarse y estar a la vanguardia,
                                    no sólo nuestro contador, es parte de
                                    nuestro consejo empresarial"{" "}
                                </p>
                                <br />
                                <p className="persona1">
                                    {" "}
                                    Lenin Pérez Islas. <br /> CEO AUTRACEN{" "}
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonio-a">
                            <div className="image fondo2"></div>
                            <div className="textoTestimonio">
                                <p className="fraseTestimonio2">
                                    {" "}
                                    "Honestidad, atención personalizada, pronta
                                    respuesta. "{" "}
                                </p>
                                <br />
                                <p className="persona2">
                                    {" "}
                                    Carolina Ortiz López. <br /> Directora
                                    Administrativa Instituto Juárez Lincoln{" "}
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonio-a">
                            <div className="image fondo3"></div>
                            <div className="textoTestimonio">
                                <p className="fraseTestimonio3">
                                    {" "}
                                    "Son personas profesionales, éticas,
                                    confiables, actualizadas, puntuales, con
                                    gran calidad humana. Te facilitan todo
                                    proceso que requiere tu empresa.
                                    ¡Ampliamente recomendable! "{" "}
                                </p>
                                <br />
                                <p className="persona3">
                                    {" "}
                                    Elvia Lucero Guzmán. <br /> Directora
                                    Gerente General Amistad Comunicaciones{" "}
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonio-a">
                            <div className="image fondo4"></div>
                            <div className="textoTestimonio">
                                <p className="fraseTestimonio4">
                                    {" "}
                                    "Inspira confianza porque saben de su
                                    trabajo, explican de una forma sencilla y
                                    práctica. "{" "}
                                </p>
                                <br />
                                <p className="persona4">
                                    {" "}
                                    Carmela González Betán. <br /> Directora
                                    Directora General Colegio Fromental La Salle{" "}
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonio-a">
                            <div className="image fondo5"></div>
                            <div className="textoTestimonio">
                                <p className="fraseTestimonio">
                                    {" "}
                                    "El personal del Despacho Godínez Madrigal
                                    siempre brinda soluciones oportunas con un
                                    trato amable y eficiente. El nivel de
                                    experiencia y actualización constante de la
                                    dirección y el equipo, permite tener un
                                    soporte confiable para la gestión de todos
                                    los procesos contables y demás procesos en
                                    que nos brindan consultoría."{" "}
                                </p>
                                <br />
                                <p className="persona4">
                                    {" "}
                                    Francisco Sill. <br /> Director General
                                    Segrak Servicios Gráficos{" "}
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonio-a">
                            <div className="image fondo6"></div>
                            <div className="textoTestimonio">
                                <p className="fraseTestimonio">
                                    {" "}
                                    Soy emprendedor y el despacho me ha brindado
                                    consultoría y soporte muy adecuado para
                                    apoyar un crecimiento continuo. El trato
                                    personalizado es muy notable, así como los
                                    tiempos de respuesta y confiabilidad en los
                                    servicios contables y laborales que nos
                                    brindan para la empresa.{" "}
                                </p>
                                <br />
                                <p className="persona4">
                                    {" "}
                                    Francisco Sill. <br /> Director TodoKleen{" "}
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
}
