import React, { Component } from 'react';

import UsefulLink from './UsefulLink';
import "../styles/TopBar.css";

class TopFooter extends Component {
    
    render() {
        return (
            <div className="flexFooter">
                
                <ul className='listfooter'>
                    <div className='footerInfoFlex'><img src="images/icon1.png" alt="" className='imageInfoFooter'/><div class="textFooter2">contacto@godinezmadrigal.com.mx</div></div>
                    <div className='footerInfoFlex'><img src="images/icon2.png" alt="" className='imageInfoFooter'/><div class="textFooter2">+52 221-593-9993</div></div>
                    <div className='footerInfoFlex'><img src="images/icon3.png" alt="" className='imageInfoFooter'/><div class="textFooter2">Av. Forjadores No.505 Primer Piso Col. Manantiales, San Pedro Cholula, Puebla.</div></div>
                </ul>

                <UsefulLink/>
                {/* <!-- /.infomation-footer --> */}
            </div>
        /* <!-- /.widget-infomation --> */
        );
    }
}

export default TopFooter;