import React, { Component } from 'react';
import BottomBar from './BottomBar';
import { TopFooter } from './footers/index';
import { Link } from 'react-router-dom';
import './styles/TopBar.css';

class Footer extends Component {
    render() {
        return (
            <div>
                <div className='footerContainer'>
                    <div className='textFooter'>
                        <a href='https://godinezmadrigal.com.mx/webmail'>
                            <img
                                src='images/logoGM.png'
                                alt=''
                                className='logoGM'
                            />
                        </a>

                        <div className='contactFooter'>
                            <TopFooter />
                        </div>
                    </div>
                    <BottomBar />
                </div>

                <a
                    href='https://wa.me/message/VVOSBCQOCPMYM1'
                    rel='noreferrer'
                    target='_blank'
                    className='contactanosButton'
                >
                    Contáctanos
                    <svg
                        fill='#000000'
                        height='20px'
                        width='20px'
                        version='1.1'
                        id='Icons'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 32 32'
                        className='svgWhatsapp'
                    >
                        <path
                            d='M17,0C8.7,0,2,6.7,2,15c0,3.4,1.1,6.6,3.2,9.2l-2.1,6.4c-0.1,0.4,0,0.8,0.3,1.1C3.5,31.9,3.8,32,4,32c0.1,0,0.3,0,0.4-0.1
	l6.9-3.1C13.1,29.6,15,30,17,30c8.3,0,15-6.7,15-15S25.3,0,17,0z M25.7,20.5c-0.4,1.2-1.9,2.2-3.2,2.4C22.2,23,21.9,23,21.5,23
	c-0.8,0-2-0.2-4.1-1.1c-2.4-1-4.8-3.1-6.7-5.8L10.7,16C10.1,15.1,9,13.4,9,11.6c0-2.2,1.1-3.3,1.5-3.8c0.5-0.5,1.2-0.8,2-0.8
	c0.2,0,0.3,0,0.5,0c0.7,0,1.2,0.2,1.7,1.2l0.4,0.8c0.3,0.8,0.7,1.7,0.8,1.8c0.3,0.6,0.3,1.1,0,1.6c-0.1,0.3-0.3,0.5-0.5,0.7
	c-0.1,0.2-0.2,0.3-0.3,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c0.3,0.5,0.9,1.4,1.7,2.1c1.2,1.1,2.1,1.4,2.6,1.6l0,0c0.2-0.2,0.4-0.6,0.7-0.9
	l0.1-0.2c0.5-0.7,1.3-0.9,2.1-0.6c0.4,0.2,2.6,1.2,2.6,1.2l0.2,0.1c0.3,0.2,0.7,0.3,0.9,0.7C26.2,18.5,25.9,19.8,25.7,20.5z'
                        />
                    </svg>
                </a>

                <div className='button-go-top'>
                    <Link
                        to='#'
                        title=''
                        className='go-top'
                    >
                        <i className='fa fa-chevron-up'></i>
                    </Link>
                </div>
            </div>
            // <!-- /#footer -->
        );
    }
}

export default Footer;
