import React from "react";
import "../styles/custom/NosotrosInfo.css";

export const NosotrosInfo = () => {
    return (
        <div className="topContainer">
            <div className="Container">
                <div className="textContainer">
                    <h2>
                        <b> ¿Quiénes somos?</b>
                        <hr />
                    </h2>{" "}
                    <p>
                        Godínez Madrigal y Asociados S. C. es una firma de
                        contadores públicos que cuenta con más de 29 años de
                        experiencia prestando servicios profesionales de
                        Auditoría, Contabilidad, Consultoría fiscal, legal y
                        constitución de sociedades mercantiles y asociaciones
                        civiles
                    </p>{" "}
                </div>
                <div className="imageWidth"></div>
            </div>
            <div className="Container">
                <div className="imageWidth2"></div>
                <div className="textContainer">
                    <h2>
                        <b> Misión</b>
                        <hr />
                    </h2>{" "}
                    <p>
                        Prestar servicios profesionales de contaduría y
                        consultoría fiscal de excelente calidad y
                        profesionalismo; para satisfacer las necesidades de cada
                        uno de nuestros clientes con atención personalizada
                        buscando siempre superar sus expectativas.
                    </p>{" "}
                </div>
                <div className="imageWidth2-2"></div>
            </div>
            <div className="Container">
                <div className="textContainer">
                    <h2>
                        <b> Visión</b>
                        <hr />
                    </h2>{" "}
                    <p>
                        Ser una firma de contadores, reconocidos por nuestros
                        clientes por rebasar expectativas y requerimientos de la
                        información contable, fiscal, administrativa y
                        consultoría fiscal, brindando seguridad en sus empresas
                        y/o negocios.
                    </p>{" "}
                </div>
                <div className="imageWidth3"></div>
            </div>
            <div className="Container">
                <div className="imageWidth4"></div>
                <div className="textContainer">
                    <h2>
                        <b> Integridad</b>
                        <hr />
                    </h2>{" "}
                    <p>
                        En Godínez Madrigal fomentamos las buenas prácticas y
                        nos apegamos a las normas impuestas por las autoridades
                        correspondientes con el único fin de hacer lo correcto
                        en cada una de las situaciones que se presentan sin
                        afectar el interés de nuestros clientes.
                    </p>{" "}
                </div>
                <div className="imageWidth4-2"></div>
            </div>
            <div className="Container">
                <div className="textContainer">
                    <h2>
                        <b> Transparencia</b>
                        <hr />
                    </h2>{" "}
                    <p>
                        Nos ganamos la confianza de nuestros clientes cumpliendo
                        de manera oportuna, puntual y correcta en la entrega de
                        su información contable, financiera y fiscal. <br /> <br /> Nuestros
                        clientes reciben cada mes una carpeta digital de sus
                        archivos de trabajo que se utilizaron para la
                        elaboración de sus declaraciones mensuales y un respaldo
                        de su contabilidad a través de la nube.
                    </p>{" "}
                </div>
                <div className="imageWidth5"></div>
            </div>
        </div>
    );
};
