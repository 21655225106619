import React from "react";
import { Link } from "react-router-dom";
import "../styles/TopBar1.css";

export const TopBar = () => {
    const [toggle, setToggle] = React.useState(false);

    return (
        <>
            <div className="topBarContainer">
                <Link to="/">
                    {" "}
                    <img src="images/Logo-GM-2.png" alt="" />{" "}
                </Link>
                <div>
                    <Link to="">
                        {" "}
                        <p className="topBarText">Home</p>
                    </Link>
                    <Link to="/Nosotros">
                        {" "}
                        <p className="topBarText">Nosotros</p>
                    </Link>
                    <Link to="/services-v1">
                        {" "}
                        <p className="topBarText">Servicios</p>
                    </Link>
                    
                    <Link to="/contact-v1">
                        {" "}
                        <p className="topBarText">Contacto</p>
                    </Link>
                </div>

                <button>Portal</button>

                <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                        setToggle(!toggle);
                    }}
                >
                    <path
                        d="M2 7a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zm0 5a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zm1 4a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3z"
                        fill="#ffff"
                    />
                </svg>
            </div>
            <div className={toggle ? "burguerMenu" : "burguerMenu2"}>
                <Link to="">
                    <div
                        className={
                            toggle ? "elementsBurguer" : "elementsBurguer2"
                        }
                    >
                        <p>Home</p>
                        <hr />
                    </div>
                </Link>
                <Link to="/Nosotros">
                    <div
                        className={
                            toggle ? "elementsBurguer" : "elementsBurguer2"
                        }
                    >
                        <p>Nosotros</p>
                        <hr />
                    </div>
                </Link>
                <Link to="/services-v1">
                    <div
                        className={
                            toggle ? "elementsBurguer" : "elementsBurguer2"
                        }
                    >
                        <p>Servicios</p>
                        <hr />
                    </div>
                </Link>
               
                <Link to="/contact-v1">
                    <div
                        className={
                            toggle ? "elementsBurguer" : "elementsBurguer2"
                        }
                    >
                        <p>Contacto</p>
                        <hr />
                    </div>
                </Link>
            </div>
        </>
    );
};
