import React, { Component } from "react";
import { QuienesSomos } from "./featured/QuienesSomos";
class Slider extends Component {
    render() {
        return (
            <>
                <div>
                    {/* <SliderComponents/> */}
                    <QuienesSomos />
                </div>
                {/* <!-- START REVOLUTION SLIDER 5.4.2 auto mode --> */}
                
            </>
        );
    }
}

export default Slider;
