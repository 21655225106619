import React from "react";
import "./styles/NumerosContacto.css";

export const NumerosContacto = () => {
    return (
        <div className="contenedorNumeros">
            <div className="titleContactanos">
                <h1>Contáctanos</h1>
            </div>
            <div className="elementsContactanos">
                <div className="sectionsInfoContactanos">
                    <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.3856 23.789L11.3831 23.7871L11.3769 23.7822L11.355 23.765C11.3362 23.7501 11.3091 23.7287 11.2742 23.7008C11.2046 23.6451 11.1039 23.5637 10.9767 23.4587C10.7224 23.2488 10.3615 22.944 9.92939 22.5599C9.06662 21.793 7.91329 20.7041 6.75671 19.419C5.60303 18.1371 4.42693 16.639 3.53467 15.0528C2.64762 13.4758 2 11.7393 2 10C2 7.34784 3.05357 4.8043 4.92893 2.92893C6.8043 1.05357 9.34784 0 12 0C14.6522 0 17.1957 1.05357 19.0711 2.92893C20.9464 4.8043 22 7.34784 22 10C22 11.7393 21.3524 13.4758 20.4653 15.0528C19.5731 16.639 18.397 18.1371 17.2433 19.419C16.0867 20.7041 14.9334 21.793 14.0706 22.5599C13.6385 22.944 13.2776 23.2488 13.0233 23.4587C12.8961 23.5637 12.7954 23.6451 12.7258 23.7008C12.6909 23.7287 12.6638 23.7501 12.645 23.765L12.6231 23.7822L12.6169 23.7871L12.615 23.7885C12.615 23.7885 12.6139 23.7894 12 23L12.6139 23.7894C12.2528 24.0702 11.7467 24.0699 11.3856 23.789ZM12 23L11.3856 23.789C11.3856 23.789 11.3861 23.7894 12 23ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z"
                            
                        />
                    </svg>
                    <h2>Dirección</h2>
                    <p>
                        Dirección Av. Forjadores No.505 Primer Piso Col.
                        Manantiales, San Pedro Cholula, Puebla.
                    </p>
                </div>
                <div className="sectionsInfoContactanos">
                    <svg
                        
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 93.465 93.465"
                    >
                        <g>
                            <path
                                d="M92.781,74.906L73.302,55.428c-0.896-0.896-2.349-0.896-3.244,0L59.825,65.661c-2.782-0.769-10.176-3.475-18.683-11.979
		c-8.826-8.827-12.402-17.625-13.289-20.113l10.173-10.173c0.431-0.43,0.673-1.014,0.673-1.623s-0.242-1.192-0.673-1.622
		L18.547,0.672c-0.896-0.896-2.349-0.896-3.245,0L3.916,12.059c-0.148,0.147-0.273,0.313-0.376,0.495l-0.021,0.024
		c-9.065,11.141,0.009,36.045,20.66,56.697c14.696,14.695,32.502,24.19,45.365,24.19c4.592,0,8.373-1.163,11.242-3.458l0.268-0.184
		c0.117-0.08,0.225-0.169,0.324-0.27L92.781,78.15c0.431-0.431,0.673-1.015,0.673-1.622C93.454,75.92,93.211,75.337,92.781,74.906z"
                            />
                        </g>
                    </svg>
                    <h2>Número</h2>
                    <p>+52 221-593-9993</p>
                </div>
                <div className="sectionsInfoContactanos">
                    <svg
                        
                        viewBox="0 0 1920 1920"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1920 428.266v1189.54l-464.16-580.146-88.203 70.585 468.679 585.904H83.684l468.679-585.904-88.202-70.585L0 1617.805V428.265l959.944 832.441L1920 428.266ZM1919.932 226v52.627l-959.943 832.44L.045 278.628V226h1919.887Z"
                            fill-rule="evenodd"
                        />
                    </svg>
                    <h2>Email</h2>
                    <p className="textoCorreo">contacto@godinezmadrigal.com.mx</p>
                </div>
            </div>
        </div>
    );
};
