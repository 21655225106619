import React, { Component } from "react";
import { Header, Footer } from "../layouts/general";
import { ContactoComponent } from "./Components/ContactoComponent";
import { NumerosContacto } from "./Components/NumerosContacto";
import { MapaContactanos } from "./Components/MapaContactanos";
import { Breadcrumbs } from "../layouts/general/featured/Breadcrumbs";

class Contact01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: "Blog",
                },
            ],
            titleheading: [
                {
                    id: "1",
                    title: "Contacto",
                },
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: "Home",
                    classicon: "fa fa-angle-right",
                    aria: "true",
                },
                {
                    id: 2,
                    title: "Contacto",
                    classicon: "",
                    aria: "",
                },
            ],
            contactinfo: [
                {
                    id: "1",
                    title: "Dirección",
                    info: "Av. Forjadores No.505 Primer Piso Col. Manantiales, San Pedro Cholula, Puebla.",
                },
                {
                    id: "2",
                    title: "WhatsApp",
                    info: "+52 2215939993",
                },
                {
                    id: "3",
                    title: "Direccion de correo",
                    info: " contacto@godinezmadrigal.com.mx",
                },
            ],
        };
    }
    render() {
        return (
            <div className="bg-body">
                <div className="boxed">
                    {/* <Loader /> */}
                    {/* <TopBar /> */}
                    {this.state.headers.map((data) => (
                        <Header data={data} key={data.id} />
                    ))}

                    <Breadcrumbs title={"Contacto"} breadCrumbs={"Home > Contacto"} imageLink={"images/contadoresContactanos.jpg"}/>

                    {/* <div className="page-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        {this.state.titleheading.map((data) => (
                                            <h1
                                                key={data.id}
                                                className="h1-title"
                                            >
                                                {data.title}
                                            </h1>
                                        ))}
                                    </div>
                                    <ul className="breadcrumbs">
                                        {this.state.breadcrumbs.map((data) => (
                                            <li key={data.id}>
                                                <Link to="#" title="">
                                                    {data.title}
                                                    <i
                                                        className={
                                                            data.classicon
                                                        }
                                                        aria-hidden={data.aria}
                                                    ></i>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <NumerosContacto />
                    <ContactoComponent />
                    <MapaContactanos />
                    
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Contact01;
