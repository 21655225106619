import React, { Component } from "react";
import "../styles/EventBoxs.css";
class EventBoxs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    id: 1,
                    srcimg: "images/imagebox/001.jpg",
                    title: "Ejemplo",
                    description:
                        "Sed ut perspiciatis unde omnis iste error sit voluptatem accusantium doloremque lau dantium, rem aperiam eaque ipsa ab illo inventore veritatis",
                },
                {
                    id: 2,
                    srcimg: "images/imagebox/002.jpg",
                    title: "Business Ideas And Innovation",
                    description:
                        "Sed ut perspiciatis unde omnis iste error sit voluptatem accusantium doloremque lau dantium, rem aperiam eaque ipsa ab illo inventore veritatis",
                },
                {
                    id: 3,
                    srcimg: "images/imagebox/003.jpg",
                    title: "Directory Of Finance Businesses",
                    description:
                        "Sed ut perspiciatis unde omnis iste error sit voluptatem accusantium doloremque lau dantium, rem aperiam eaque ipsa ab illo inventore veritatis",
                },
            ],
        };
    }

    render() {
        return (
            <>
                <div>
                    <h1>Nuestros Servicios</h1>
                    <div className="gridNotes">
                        <div className="item-a">
                            <svg
                                fill="#ffff"
                                version="1.1"
                                id="Layer_1"
                                width="50px"
                                height="50px"
                                viewBox="0 0 256 240"
                                enableBackground="new 0 0 256 240"
                            >
                                <path
                                    d="M84.635,20.256c18.383,0,33.286,14.903,33.286,33.286s-14.903,33.286-33.286,33.286S51.349,71.925,51.349,53.542
	S66.251,20.256,84.635,20.256z M31.002,145.011c0-2.499,1.606-4.194,4.194-4.194s4.194,1.606,4.194,4.194v92.986h91.469v-92.986
	c0-2.499,1.606-4.194,4.194-4.194c2.499,0,4.194,1.606,4.194,4.194v92.986h29.092V136.623c0-22.934-18.74-41.585-41.585-41.585
	h-8.388l-24.451,38.015l-2.945-28.467l4.016-9.638H76.96l4.016,9.638l-3.123,28.645L53.401,95.038h-9.816
	C20.651,95.038,2,113.778,2,136.623v101.375h29.092v-92.986H31.002z M181,2v108h73V2H181z M204,93h-13V83h13V93z M204,76h-13V66h13
	V76z M204,59h-13V49h13V59z M224,93h-13V83h13V93z M224,76h-13V66h13V76z M224,59h-13V49h13V59z M244,93h-13V83h13V93z M244,76h-13
	V66h13V76z M244,59h-13V49h13V59z M244,36h-53V19h53V36z"
                                />
                            </svg>
                            <h2>
                                Servicios Contables <br /> y Fiscales
                            </h2>
                            <br />
                            <div>
                                <ul>
                                    <li>
                                        Cálculo y determinación de impuestos
                                    </li>
                                    <li>Servicios laborales</li>
                                    <li>Diagnóstico fiscal de la empresa</li>
                                </ul>
                            </div>
                        </div>
                        <div className="item-b">
                            <svg
                                width="50px"
                                height="50px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g opacity="0.4">
                                    <path
                                        d="M9.5 13.7502C9.5 14.7202 10.25 15.5002 11.17 15.5002H13.05C13.85 15.5002 14.5 14.8202 14.5 13.9702C14.5 13.0602 14.1 12.7302 13.51 12.5202L10.5 11.4702C9.91 11.2602 9.51001 10.9402 9.51001 10.0202C9.51001 9.18023 10.16 8.49023 10.96 8.49023H12.84C13.76 8.49023 14.51 9.27023 14.51 10.2402"
                                        stroke="#ffff"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M12 7.5V16.5"
                                        stroke="#ffff"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </g>
                                <path
                                    d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2"
                                    stroke="#ffff"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M17 3V7H21"
                                    stroke="#ffff"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M22 2L17 7"
                                    stroke="#ffff"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <h2>Devolución de Saldos a Favor y Fiscales</h2>
                            <br /> <br />
                            <p>
                                Nos encargamos de que las empresas y personas
                                físicas obtengan la devolución de sus saldos a
                                favor que se tienen de estos impuestos.
                            </p>
                        </div>
                        <div className="item-c">
                            <svg
                                fill="black"
                                xmlns="http://www.w3.org/2000/svg"
                                width="75"
                                height="75"
                                viewBox="0 0 100 100"
                            >
                                <g>
                                    <g>
                                        <path
                                            d="M38,30h4c0.6,0,1-0.4,1-1v-3h14v3c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1v-3c0-3.3-2.7-6-6-6H43c-3.3,0-6,2.7-6,6
			v3C37,29.6,37.4,30,38,30z"
                                        />
                                    </g>
                                    <g>
                                        <path
                                            d="M74,36H26c-3.3,0-6,2.7-6,6v32c0,3.3,2.7,6,6,6h48c3.3,0,6-2.7,6-6V42C80,38.7,77.3,36,74,36z M50.9,68
			c-2.3,0-4.4-0.6-6.2-1.6c-0.2-0.1-0.4-0.1-0.7-0.1L40,68c-0.6,0.2-1.2-0.4-1-1l1.7-4.1c0.1-0.2,0-0.5-0.1-0.6
			c-1-1.6-1.5-3.4-1.5-5.4c0-6.1,5.3-11,11.8-11s11.8,4.9,11.8,11C62.7,63.1,57.4,68,50.9,68z"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <h2>Servicios Legales</h2>
                            <br /> <br />
                            <p>
                                Asesoramos a los emprendedores, orientándolos
                                desde la factibilidad hasta la constitución de
                                la empresa.
                            </p>
                        </div>
                        <div className="item-d">
                            <svg
                                fill="#ffff"
                                width="50px"
                                height="50px"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm9.636-9.32v-1c0-.198-.133-.33-.335-.33h-.665v-1.004c0-.199-.133-.334-.331-.334h-.666v-7.345h.662c.199 0 .335-.132.335-.334v-1h.665a.322.322 0 00.331-.268c.034-.166-.033-.298-.165-.368l-9.318-4.672a.46.46 0 00-.298 0l-9.652 4.672a.333.333 0 00-.166.364c0 .17.133.272.298.272h1v1c0 .198.133.33.331.33h.666v7.349h-.662c-.2 0-.335.132-.335.33v1.004h-.665c-.2 0-.332.132-.332.331v1.003c-.198 0-.334.133-.334.335s.132.334.331.334h19.305c.198 0 .334-.132.334-.33 0-.2-.132-.339-.33-.339h-.004zM7.993 11.998v-.665h15.974v.665H7.993zm13.643.67v7.344h-1.328v-7.345h1.328zm-3.328 0v7.344h-1.331v-7.345h1.33zm-3.328 0v7.344h-1.328v-7.345h1.328zm-3.328 0v7.344h-1.327v-7.345h1.327zm12.315 8.678H7.997v-.669H23.97v.669h-.003z" />
                            </svg>
                            <h2>Gobierno Corporativo en las Empresas</h2>
                            <br /> <br />
                            <p>
                                Ayudamos a los empresarios a implementar el
                                Gobierno Corporativo y el código de mejores
                                prácticas de gobierno corporativo.
                            </p>
                        </div>
                        <div className="item-e">
                            <svg
                                width="50px"
                                height="50px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10 9C10 8.60444 10.1173 8.21776 10.3371 7.88886C10.5568 7.55996 10.8692 7.30362 11.2346 7.15224C11.6001 7.00087 12.0022 6.96126 12.3902 7.03843C12.7781 7.1156 13.1345 7.30608 13.4142 7.58579C13.6939 7.86549 13.8844 8.22186 13.9616 8.60982C14.0387 8.99778 13.9991 9.39992 13.8478 9.76537C13.6964 10.1308 13.44 10.4432 13.1111 10.6629C12.7822 10.8827 12.3956 11 12 11V12M14.25 19L12.8 20.9333C12.4 21.4667 11.6 21.4667 11.2 20.9333L9.75 19H7C4.79086 19 3 17.2091 3 15V7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V15C21 17.2091 19.2091 19 17 19H14.25Z"
                                    stroke="#ffff"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <circle cx="12" cy="15" r="1" fill="#ffff" />
                            </svg>
                            <h2>Atención de Invitaciones y Auditorías</h2>
                            <br /> <br />
                            <p>
                                Contamos con un equipo especialista en cada
                                materia para apoyar a las empresas a enfrentar
                                el proceso.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EventBoxs;
