import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { TopBar } from "./featured/TopBar";
import "./styles/TopBar.css";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linklogo: "/",
        };
    }

    render() {
        return (
            <>
                <TopBar/>
                
            </>
            // {/* <!-- /header --> */}
        );
    }
}

export default withRouter(Header);
