import React from "react";
import '../styles/map.css';

const Map = () => {
    return (
        <div className="containerMap">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.710795049041!2d-98.2826924706423!3d19.076449015628956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc668f9a61fe5%3A0xcb5fb62854b993b!2sBlvd.%20Forjadores%20de%20Puebla%20505%2C%20Col.%20Manantiales%2C%20Geovillas%20el%20Campanario%20Secc%20II%2C%20Puebla%2C%20Pue.!5e0!3m2!1ses!2smx!4v1677772700338!5m2!1ses!2smx"
                width="100%"
                height="100%"
                title="Map"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    );
};

export default Map;
