const menus = [
    {
        id: 1,
        name: "Home",
        linkmenu: "/",
    },
    {
        id: 2,
        name: "Nosotros",
        linkmenu: "/Nosotros",
    },
    {
        id: 3,
        name: "Servicios",
        linkmenu: "/services-v1",
    },

    /* {
        id: 5,
        name: "Bolsa de trabajo",
        linkmenu: "/blog",
    }, */
    {
        id: 6,
        name: "Contacto",
        linkmenu: "/contact-v1",
    },
    /* {
        id: 7,
        name: "Almacén",
        linkmenu: "/blog",
    }, */
    /* {
        id: 8,
        name: "Blog",
        linkmenu: "/blog",
    }, */
];

export default menus;
