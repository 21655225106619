import React from "react";
import "./styles/NumerosContacto2.css";

export const NumerosContacto2 = () => {
    return (
        <div className="cajaNumeros">
            <div className="elementosContactanos">
                <div className="seccionesInfoContactanos">
                    <div className="iconosTitle">
                        <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.3856 23.789L11.3831 23.7871L11.3769 23.7822L11.355 23.765C11.3362 23.7501 11.3091 23.7287 11.2742 23.7008C11.2046 23.6451 11.1039 23.5637 10.9767 23.4587C10.7224 23.2488 10.3615 22.944 9.92939 22.5599C9.06662 21.793 7.91329 20.7041 6.75671 19.419C5.60303 18.1371 4.42693 16.639 3.53467 15.0528C2.64762 13.4758 2 11.7393 2 10C2 7.34784 3.05357 4.8043 4.92893 2.92893C6.8043 1.05357 9.34784 0 12 0C14.6522 0 17.1957 1.05357 19.0711 2.92893C20.9464 4.8043 22 7.34784 22 10C22 11.7393 21.3524 13.4758 20.4653 15.0528C19.5731 16.639 18.397 18.1371 17.2433 19.419C16.0867 20.7041 14.9334 21.793 14.0706 22.5599C13.6385 22.944 13.2776 23.2488 13.0233 23.4587C12.8961 23.5637 12.7954 23.6451 12.7258 23.7008C12.6909 23.7287 12.6638 23.7501 12.645 23.765L12.6231 23.7822L12.6169 23.7871L12.615 23.7885C12.615 23.7885 12.6139 23.7894 12 23L12.6139 23.7894C12.2528 24.0702 11.7467 24.0699 11.3856 23.789ZM12 23L11.3856 23.789C11.3856 23.789 11.3861 23.7894 12 23ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z"
                            />
                        </svg>
                        <h2>Dirección</h2>
                    </div>
                    <p>
                        Dirección Av. Forjadores No.505 Primer Piso Col.
                        Manantiales, San Pedro Cholula, Puebla.
                    </p>
                </div>
                <div className="seccionesInfoContactanos">
                    <div className="iconosTitle">
                        <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 93.465 93.465"
                        >
                            <g>
                                <path
                                    d="M92.781,74.906L73.302,55.428c-0.896-0.896-2.349-0.896-3.244,0L59.825,65.661c-2.782-0.769-10.176-3.475-18.683-11.979
                        c-8.826-8.827-12.402-17.625-13.289-20.113l10.173-10.173c0.431-0.43,0.673-1.014,0.673-1.623s-0.242-1.192-0.673-1.622
                        L18.547,0.672c-0.896-0.896-2.349-0.896-3.245,0L3.916,12.059c-0.148,0.147-0.273,0.313-0.376,0.495l-0.021,0.024
                        c-9.065,11.141,0.009,36.045,20.66,56.697c14.696,14.695,32.502,24.19,45.365,24.19c4.592,0,8.373-1.163,11.242-3.458l0.268-0.184
                        c0.117-0.08,0.225-0.169,0.324-0.27L92.781,78.15c0.431-0.431,0.673-1.015,0.673-1.622C93.454,75.92,93.211,75.337,92.781,74.906z"
                                />
                            </g>
                        </svg>
                        <h2>Número</h2>
                    </div>

                    <p>+52 221-593-9993</p>
                </div>
                <div className="seccionesInfoContactanos">
                    <div className="iconosTitle">
                        <svg
                            viewBox="0 0 1920 1920"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1920 428.266v1189.54l-464.16-580.146-88.203 70.585 468.679 585.904H83.684l468.679-585.904-88.202-70.585L0 1617.805V428.265l959.944 832.441L1920 428.266ZM1919.932 226v52.627l-959.943 832.44L.045 278.628V226h1919.887Z"
                                fill-rule="evenodd"
                            />
                        </svg>
                        <h2>Email</h2>
                    </div>
                    <div className="emailText">
                        contacto@godinezmadrigal.com.mx
                    </div>
                </div>
                <div className="seccionesInfoContactanos">
                    <div className="iconosTitle">
                        <svg
                            width="800px"
                            height="800px"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="2"
                                y="2"
                                width="28"
                                height="28"
                                rx="6"
                                fill="url(#paint0_radial_87_7153)"
                            />
                            <rect
                                x="2"
                                y="2"
                                width="28"
                                height="28"
                                rx="6"
                                fill="url(#paint1_radial_87_7153)"
                            />
                            <rect
                                x="2"
                                y="2"
                                width="28"
                                height="28"
                                rx="6"
                                fill="url(#paint2_radial_87_7153)"
                            />
                            <path
                                d="M23 10.5C23 11.3284 22.3284 12 21.5 12C20.6716 12 20 11.3284 20 10.5C20 9.67157 20.6716 9 21.5 9C22.3284 9 23 9.67157 23 10.5Z"
                                fill="white"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21ZM16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
                                fill="white"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6 15.6C6 12.2397 6 10.5595 6.65396 9.27606C7.2292 8.14708 8.14708 7.2292 9.27606 6.65396C10.5595 6 12.2397 6 15.6 6H16.4C19.7603 6 21.4405 6 22.7239 6.65396C23.8529 7.2292 24.7708 8.14708 25.346 9.27606C26 10.5595 26 12.2397 26 15.6V16.4C26 19.7603 26 21.4405 25.346 22.7239C24.7708 23.8529 23.8529 24.7708 22.7239 25.346C21.4405 26 19.7603 26 16.4 26H15.6C12.2397 26 10.5595 26 9.27606 25.346C8.14708 24.7708 7.2292 23.8529 6.65396 22.7239C6 21.4405 6 19.7603 6 16.4V15.6ZM15.6 8H16.4C18.1132 8 19.2777 8.00156 20.1779 8.0751C21.0548 8.14674 21.5032 8.27659 21.816 8.43597C22.5686 8.81947 23.1805 9.43139 23.564 10.184C23.7234 10.4968 23.8533 10.9452 23.9249 11.8221C23.9984 12.7223 24 13.8868 24 15.6V16.4C24 18.1132 23.9984 19.2777 23.9249 20.1779C23.8533 21.0548 23.7234 21.5032 23.564 21.816C23.1805 22.5686 22.5686 23.1805 21.816 23.564C21.5032 23.7234 21.0548 23.8533 20.1779 23.9249C19.2777 23.9984 18.1132 24 16.4 24H15.6C13.8868 24 12.7223 23.9984 11.8221 23.9249C10.9452 23.8533 10.4968 23.7234 10.184 23.564C9.43139 23.1805 8.81947 22.5686 8.43597 21.816C8.27659 21.5032 8.14674 21.0548 8.0751 20.1779C8.00156 19.2777 8 18.1132 8 16.4V15.6C8 13.8868 8.00156 12.7223 8.0751 11.8221C8.14674 10.9452 8.27659 10.4968 8.43597 10.184C8.81947 9.43139 9.43139 8.81947 10.184 8.43597C10.4968 8.27659 10.9452 8.14674 11.8221 8.0751C12.7223 8.00156 13.8868 8 15.6 8Z"
                                fill="white"
                            />
                            <defs>
                                <radialGradient
                                    id="paint0_radial_87_7153"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(12 23) rotate(-55.3758) scale(25.5196)"
                                >
                                    <stop stop-color="#B13589" />
                                    <stop
                                        offset="0.79309"
                                        stop-color="#C62F94"
                                    />
                                    <stop offset="1" stop-color="#8A3AC8" />
                                </radialGradient>
                                <radialGradient
                                    id="paint1_radial_87_7153"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(11 31) rotate(-65.1363) scale(22.5942)"
                                >
                                    <stop stop-color="#E0E8B7" />
                                    <stop
                                        offset="0.444662"
                                        stop-color="#FB8A2E"
                                    />
                                    <stop
                                        offset="0.71474"
                                        stop-color="#E2425C"
                                    />
                                    <stop
                                        offset="1"
                                        stop-color="#E2425C"
                                        stop-opacity="0"
                                    />
                                </radialGradient>
                                <radialGradient
                                    id="paint2_radial_87_7153"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(0.500002 3) rotate(-8.1301) scale(38.8909 8.31836)"
                                >
                                    <stop
                                        offset="0.156701"
                                        stop-color="#406ADC"
                                    />
                                    <stop
                                        offset="0.467799"
                                        stop-color="#6A45BE"
                                    />
                                    <stop
                                        offset="1"
                                        stop-color="#6A45BE"
                                        stop-opacity="0"
                                    />
                                </radialGradient>
                            </defs>
                        </svg>
                        <h2>Instagram</h2>
                    </div>
                    <div className="emailText">
                        <a href="https://instagram.com/godinezmadrigalac?igshid=YmMyMTA2M2Y=">
                            @godinezmadrigalac
                        </a>
                    </div>
                </div>
                <div className="seccionesInfoContactanos">
                    <div className="iconosTitle">
                        <svg
                            width="800px"
                            height="800px"
                            viewBox="0 -4 48 48"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>Twitter-color</title>
                            <desc>Created with Sketch.</desc>
                            <defs></defs>
                            <g
                                id="Icons"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <g
                                    id="Color-"
                                    transform="translate(-300.000000, -164.000000)"
                                    fill="#00AAEC"
                                >
                                    <path
                                        d="M348,168.735283 C346.236309,169.538462 344.337383,170.081618 342.345483,170.324305 C344.379644,169.076201 345.940482,167.097147 346.675823,164.739617 C344.771263,165.895269 342.666667,166.736006 340.418384,167.18671 C338.626519,165.224991 336.065504,164 333.231203,164 C327.796443,164 323.387216,168.521488 323.387216,174.097508 C323.387216,174.88913 323.471738,175.657638 323.640782,176.397255 C315.456242,175.975442 308.201444,171.959552 303.341433,165.843265 C302.493397,167.339834 302.008804,169.076201 302.008804,170.925244 C302.008804,174.426869 303.747139,177.518238 306.389857,179.329722 C304.778306,179.280607 303.256911,178.821235 301.9271,178.070061 L301.9271,178.194294 C301.9271,183.08848 305.322064,187.17082 309.8299,188.095341 C309.004402,188.33225 308.133826,188.450704 307.235077,188.450704 C306.601162,188.450704 305.981335,188.390033 305.381229,188.271578 C306.634971,192.28169 310.269414,195.2026 314.580032,195.280607 C311.210424,197.99061 306.961789,199.605634 302.349709,199.605634 C301.555203,199.605634 300.769149,199.559408 300,199.466956 C304.358514,202.327194 309.53689,204 315.095615,204 C333.211481,204 343.114633,188.615385 343.114633,175.270495 C343.114633,174.831347 343.106181,174.392199 343.089276,173.961719 C345.013559,172.537378 346.684275,170.760563 348,168.735283"
                                        id="Twitter"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                        <h2>Twitter</h2>
                    </div>
                    <div className="emailText">
                        <a href="https://twitter.com/godinezmadrigal?s=21&t=-uTOpMat0PyyvrXKiv4V3A">
                            @godinezmadrigal
                        </a>{" "}
                    </div>
                </div>
                <div className="seccionesInfoContactanos">
                    <div className="iconosTitle">
                        <svg
                            width="800px"
                            height="800px"
                            viewBox="0 0 48 48"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="Icons"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <g
                                    id="Color-"
                                    transform="translate(-200.000000, -160.000000)"
                                    fill="#4460A0"
                                >
                                    <path
                                        d="M225.638355,208 L202.649232,208 C201.185673,208 200,206.813592 200,205.350603 L200,162.649211 C200,161.18585 201.185859,160 202.649232,160 L245.350955,160 C246.813955,160 248,161.18585 248,162.649211 L248,205.350603 C248,206.813778 246.813769,208 245.350955,208 L233.119305,208 L233.119305,189.411755 L239.358521,189.411755 L240.292755,182.167586 L233.119305,182.167586 L233.119305,177.542641 C233.119305,175.445287 233.701712,174.01601 236.70929,174.01601 L240.545311,174.014333 L240.545311,167.535091 C239.881886,167.446808 237.604784,167.24957 234.955552,167.24957 C229.424834,167.24957 225.638355,170.625526 225.638355,176.825209 L225.638355,182.167586 L219.383122,182.167586 L219.383122,189.411755 L225.638355,189.411755 L225.638355,208 L225.638355,208 Z"
                                        id="Facebook"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                        <h2>Facebook</h2>
                    </div>
                    <div className="emailText">
                        <a href="https://www.facebook.com/godinezmadrigalmx/">
                            @godinezmadrigalmx
                        </a>{" "}
                    </div>
                </div>
            </div>
        </div>
    );
};
