import React, { useState } from 'react';
import '../styles/ServiciosComponent.css';

export const ServiciosComponent = () => {
    const [elementSelected, setElementSelected] = useState([
        { name: 'Servicios Contables y Fiscales', value: true },
        { name: 'Devolución de Saldos a Favor', value: false },
        { name: 'Servicios Legales', value: false },
        { name: 'Gobierno Corporativo en las Empresas', value: false },
        { name: 'Atención de Invitaciones y Auditorías', value: false },
    ]);

    const selectedItem = (item) => {
        let ArrTemp = [];
        elementSelected.forEach((element) => {
            if (String(element.name) === String(item)) {
                ArrTemp.push({ name: item, value: true });
            } else {
                ArrTemp.push({ name: element.name, value: false });
            }
        });
        setElementSelected(ArrTemp);
    };

    return (
        <div className='serviciosComponent'>
            <aside>
                <div className='selectionTab'>
                    <div
                        className={
                            elementSelected[0].value
                                ? 'selectedSideElement'
                                : 'serviciosSideElement'
                        }
                        onClick={(e) => {
                            selectedItem(e.target.innerText);
                        }}
                    >
                        Servicios Contables y Fiscales
                    </div>
                    <div
                        className={
                            elementSelected[1].value
                                ? 'selectedSideElement'
                                : 'serviciosSideElement'
                        }
                        onClick={(e) => {
                            selectedItem(e.target.innerText);
                        }}
                    >
                        Devolución de Saldos a Favor
                    </div>
                    <div
                        className={
                            elementSelected[2].value
                                ? 'selectedSideElement'
                                : 'serviciosSideElement'
                        }
                        onClick={(e) => {
                            selectedItem(e.target.innerText);
                        }}
                    >
                        Servicios Legales
                    </div>
                    <div
                        className={
                            elementSelected[3].value
                                ? 'selectedSideElement'
                                : 'serviciosSideElement'
                        }
                        onClick={(e) => {
                            selectedItem(e.target.innerText);
                        }}
                    >
                        Gobierno Corporativo en las Empresas
                    </div>
                    <div
                        className={
                            elementSelected[4].value
                                ? 'selectedSideElement'
                                : 'serviciosSideElement'
                        }
                        onClick={(e) => {
                            selectedItem(e.target.innerText);
                        }}
                    >
                        Atención de Invitaciones y Auditorías
                    </div>
                </div>

                <div className='godinezMadrigalLogo'></div>
            </aside>
            <div className='serviciosContent'>
                {elementSelected[0].value ? (
                    <div className='serviciosContables'>
                        <h1>Servicios Contables y Fiscales</h1>
                        <br />
                        <br />
                        <h3>CÁLCULO Y DETERMINACIÓN DE IMPUESTOS</h3>
                        <br />
                        <p>
                            Calculamos y determinamos tus impuestos, ya sea
                            personas física o moral, a partir de tu información
                            para dar cumplimiento a tus obligaciones contables y
                            fiscales en tiempo y forma ante las autoridades
                            correspondientes.
                        </p>
                        <br />
                        <h3>SERVICIOS LABORALES</h3>
                        <br />
                        <p>
                            Nos encargamos de que tu empresa/asociación cumpla
                            con todas las obligaciones correspondientes
                            establecidas por la Ley Federal del Trabajo, Ley del
                            Seguro Social y Ley del ISR; cubriendo cada área
                            desde los contratos, elaboración y timbrado de
                            nóminas.
                        </p>
                        <br />
                        <h3>DIAGNÓSTICO FISCAL DE LA EMPRESA</h3>
                        <br />

                        <p>
                            Realizamos un diagnóstico de la situación que guarda
                            tu empresa ante el SAT del debido cumplimiento
                            correcto y oportuno de tus obligaciones fiscales,
                            con el propósito de anticipar alguna corrección
                            antes de que se incurra en multas y recargos por la
                            mala presentación o cálculo incorrecto de las
                            declaraciones.
                        </p>
                    </div>
                ) : (
                    <></>
                )}
                {elementSelected[1].value ? (
                    <div>
                        <h1>Devolución de Saldos a Favor</h1>
                        <br />
                        <br />

                        <p>
                            En Godínez Madrigal contamos con una amplia
                            experiencia y un equipo de profesionales para apoyar
                            a las empresas y personas físicas a obtener la
                            devolución de sus saldos a favor de sus impuestos.
                            Para ello preparamos todos sus archivos de trabajo y
                            realizamos toda la gestión para obtener la correcta
                            devolución.
                        </p>
                        <br />
                        <h3>¿SABÍAS QUÉ?</h3>
                        <br />
                        <p>
                            Las personas físicas y morales tiene el derecho a
                            solicitar en devolucion. sus saldos a favor que
                            obtenga de sus impuestos de I.S.R., I.V.A.,
                            I.E.P.S., pagos indebidos, etc.
                        </p>
                        <br />
                        <h3>¿Como ocurre esto?</h3>
                        <br />

                        <p>
                            Por ejemplo, esto es posible debido a que existen
                            productos y servicios que no causan este impuesto,
                            pero las empresa sí lo paga en sus compras y gastos,
                            estos impuestos pueden ser:
                        </p>

                        <ul>
                            <li>I.V.A.</li>
                            <li>I.S.R.</li>
                            <li>I.E.P.S</li>
                            <li>Pagos INDEBIDOS</li>
                        </ul>
                        <br />
                        <p>
                            Empresas de la industria alimenticia, agroquimicos,
                            del sector primario, etc. La oportuna atención y
                            correcta correccion evitará que se paguen multas y
                            actos de molestia.
                        </p>
                    </div>
                ) : (
                    <></>
                )}
                {elementSelected[2].value ? (
                    <div>
                        <h1>Servicios Legales</h1>
                        <br />
                        <br />
                        <h3>También los apoyamos en lo siguientes temas:</h3>
                        <br />
                        <ul>
                            <li>Arranque de Empresas.</li>
                            <li>Adquisiciones Inmobiliarias.</li>
                            <li>
                                Estructuras de Negocio con Empresas de Grupo.
                            </li>
                            <li>
                                Fusiones, Escisiones, Disoluciones y
                                Transformaciones.
                            </li>
                            <li>
                                Constitución y Registro de Sociedades Civiles y
                                Mercantiles.
                            </li>
                            <li>
                                Constitución y Registro de Asociaciones Civiles.
                            </li>
                            <li>
                                Asesoría para la Celebración de Asambleas de
                                Socios o Accionistas.
                            </li>
                            <li> Liquidación de Empresas.</li>
                            <li>Valuación de activos intangibles.</li>
                        </ul>
                    </div>
                ) : (
                    <></>
                )}
                {elementSelected[3].value ? (
                    <div>
                        <h1>Gobierno Corporativo en las Empresas</h1>
                        <br />
                        <br />

                        <p>
                            El gobierno corporativo es el marco de normas y
                            prácticas, que se refieren a las estructuras y
                            procesos para la dirección de las compañías, la
                            incorporación de los Principios y las Mejores
                            Prácticas de Gobierno Corporativo recomendadas en el
                            Código en la cultura de las sociedades, las ayudará
                            en su institucionalización, en la transparencia de
                            sus operaciones y en la revelación adecuada de
                            información confiable; a ser competitivas; a acceder
                            a fuentes de financiamiento en condiciones
                            favorables; a tener procesos de sucesión estables y
                            a ser permanentes en el tiempo en beneficio de sus
                            socios o asociados y de los terceros interesados.
                        </p>
                    </div>
                ) : (
                    <></>
                )}
                {elementSelected[4].value ? (
                    <div>
                        <h1>Atención de Invitaciones y Auditorías</h1>
                        <br />
                        <br />

                        <p>
                            En Godínez Madrigal contamos con un equipo
                            especialista en cada materia para apoyar a las
                            empresas a enfrentar el proceso de una invitación o
                            en el caso de que ya se cuente con auditoría
                            iniciada por una autoridad fiscalizadora.
                        </p>
                        <br />
                        <p>
                            En los últimos tiempos el SAT y otras dependencias
                            fiscalizadoras han invitado a las empresas a
                            corregir ciertas irregularidades en materia fiscal,
                            INFONAVIT, Seguro Social, entre muchas otras más.
                        </p>
                        <br />
                        <p>
                            La oportuna atención y correcta corrección evitará
                            que se paguen multas y actos de molestia.
                        </p>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
