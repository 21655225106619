import React, { useRef } from "react";
import "./styles/ContactoComponent.css";
import toast, { Toaster } from "react-hot-toast"; /* SmtpJS.com - v3.0.0 */
import emailjs from "@emailjs/browser";

export const ContactoComponent = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        console.log("hola");

        emailjs
            .sendForm(
                "service_m49hw3k",
                "template_0lgm4db",
                form.current,
                "9bStrZcyhWPpL4pvM"
            )
            .then(
                (result) => {
                    toast.success("El correo se envió de manera exitosa");
                },
                (error) => {
                    toast.error(error.text);
                }
            );
    };

    return (
        <div className="contenedorContacto">
            <div className="queremosSaber">
                <div>
                    <h1> Queremos saber de ti </h1>
                </div>
                <div className="marginTopContactanos">
                    <h4> Contáctanos </h4>
                </div>
            </div>

            <div className="formulario">
                <form ref={form} onSubmit={sendEmail}>
                    <div className="inputsContacto">
                        <input type="text" placeholder="Nombre" name="nombre" />
                        <input
                            type="text"
                            name="telefono"
                            placeholder="Teléfono"
                        />
                        <input type="text" name="correo" placeholder="correo" />
                    </div>
                    <textarea
                        placeholder="Mensaje"
                        name="mensaje"
                        id=""
                        cols="30"
                        rows="10"
                    ></textarea>
                    <div className="rightButton">
                        <button className="buttonContacto">Enviar</button>
                    </div>
                </form>
            </div>
            <Toaster />
        </div>
    );
};
