import React, { Component } from 'react';
import "./styles/TopBar.css";
class BottomBar extends Component {
    render() {
        return (
            <div className="footer-bottom footerBottom">
                    <div className="container">
                        <div className="flexCopyright">
                            <div className="copyrightCenter">
                                <div className="copyright">
                                    <p>Godínez Madrigal. 2022</p>
                                </div>
                                {/* <ul className="menu-footer">
                                    <li><Link to="/" onClick={() => {window.location.href="/"}} title="">Home</Link></li>
                                    <li><Link to="/blog" onClick={() => {window.location.href="/blog"}} title="">My Blog</Link></li>
                                    <li><Link to="/" onClick={() => {window.location.href="/"}} title="">My Home</Link></li>
                                    <li><Link to="#" title="">Sample Page</Link></li>
                                </ul> */}
                                {/* <!-- /.menu-footer --> */}
                            </div>
                        </div>
                    </div>
                </div>
                /* <!-- /.footer-bottom --> */
        );
    }
}

export default BottomBar;