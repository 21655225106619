import React, { Component } from 'react';
import { Header, Footer } from '../layouts/general';
import { Breadcrumbs } from '../layouts/general/featured/Breadcrumbs';
import { NosotrosInfo } from './Components/NosotrosInfo';
import { TopBar } from '../layouts/general/featured/TopBar';
class Nosotros extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Servicios'
                }
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: 'Home',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 2,
                    title: 'Nosotros',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                
            ]
        }
    }
    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    {/* <Loader /> */}
                    {/* <TopBar /> */}
                    <TopBar/>
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }

                    <Breadcrumbs title={"Nosotros"} breadCrumbs={"Home > Nosotros"} imageLink={"images/contadoresNosotros.jpg"}/>

                    
                    <NosotrosInfo/>
                    
                    
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Nosotros;