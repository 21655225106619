import React, { Component } from "react";
import "../styles/ubicacion.css";
import Map from "../featured/Map";
import { NumerosContacto2 } from "../../../pages/Components/NumerosContacto2";


class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titleProject: [
                {
                    id: 1,
                    title: "Our Projects",
                },
            ],
        };
    }

    render() {
        return (
            <div className="projectContainer">
                <div className="containerUbicamos">
                    <div className="contactoColor">
                        <NumerosContacto2/>
                    </div>
                    <div className="ubicamosMapa">
                        <div>
                            <h1>¡Encuéntranos en la Ciudad de Puebla!</h1>
                            
                            
                        </div>

                        <Map />
                    </div>
                </div>
            </div>
        );
    }
}

export default Project;
