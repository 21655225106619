const dataBlog = [
    {
        id: 1,
        srcimg: 'images/blog/blog-post-01.png',
        title: 'WHY DO I NEED TO USE FINANCIAL ?',
        time: '11 Jan 2021',
        author: 'admin',
        category: 'Business',
        comments: 'No comment',
        description: 'Sed facilisis lorem in orci bibendum ullamcorper. Mauris vitae augue elementum, sodales nulla a, semper ligula. Nullam vel enim risus. Integer rhoncus hendrerit sem egestas porttitor. Integer et mi sed dolor eleifend pretium quis ut velit. Nam sit amet arcu feugiat, consequat orci at, ultrices magna'
    },
    {
        id: 2,
        srcimg: 'images/blog/blog-post-02.png',
        title: 'Why your sales forecast is off',
        time: '11 Jan 2021',
        author: 'admin',
        category: 'Finanve',
        comments: 'No comment',
        description: 'Sed facilisis lorem in orci bibendum ullamcorper. Mauris vitae augue elementum, sodales nulla a, semper ligula. Nullam vel enim risus. Integer rhoncus hendrerit sem egestas porttitor. Integer et mi sed dolor eleifend pretium quis ut velit. Nam sit amet arcu feugiat, consequat orci at, ultrices magna'
    },
    {
        id: 3,
        srcimg: 'images/blog/blog-post-03.png',
        title: '6 tips to retain your top sales talent',
        time: '11 Jan 2021',
        author: 'admin',
        category: 'Insurance, Leasing',
        comments: 'No comment',
        description: 'Sed facilisis lorem in orci bibendum ullamcorper. Mauris vitae augue elementum, sodales nulla a, semper ligula. Nullam vel enim risus. Integer rhoncus hendrerit sem egestas porttitor. Integer et mi sed dolor eleifend pretium quis ut velit. Nam sit amet arcu feugiat, consequat orci at, ultrices magna'
    },
    
]
export default dataBlog;