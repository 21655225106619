import React, { Component } from "react";
import { Testimonios } from "../featured/Testimonios";
class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titleServices: [
                {
                    id: 1,
                    title: "Our Services",
                },
                {
                    id: 2,
                    title: "Our Services 2",
                },
            ],
        };
    }
    render() {
        return (
            <>
				<Testimonios/>
            </>
            // <!-- /.flat-row-iconbox -->
        );
    }
}

export default Services;
