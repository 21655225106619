import React from "react";
import "../styles/QuienesSomos.css";

export const QuienesSomos = () => {
    return (
        <div className="quienesSomosContainer">
            <div className="textoQuienesSomos">
                <h1>¿Quiénes Somos?</h1>
                <p>
                    Godínez Madrigal y Asociados S. C. es una firma de
                    contadores públicos que cuenta con más de 29 años de
                    experiencia prestando servicios profesionales de Auditoría,
                    Contabilidad, Consultoría fiscal, legal y constitución de
                    sociedades mercantiles y asociaciones civiles.
                </p>
            </div>
            <div className="imageContainer">
                <img src="images/gmNuevo.png" alt="Logo Godinez Madrigal" />
                
            </div>
        </div>
    );
};
