import React, { Component } from "react";
class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titleNews: [
                {
                    id: 1,
                    title: "Latest news",
                },
            ],
        };
    }

    render() {
        return (
            <>
                
            </>
        );
    }
}

export default Blog;
